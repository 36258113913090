import { render, staticRenderFns } from "./redirect-card.vue?vue&type=template&id=0ce0ed06&scoped=true&"
import script from "./redirect-card.vue?vue&type=script&lang=js&"
export * from "./redirect-card.vue?vue&type=script&lang=js&"
import style0 from "./redirect-card.vue?vue&type=style&index=0&id=0ce0ed06&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ce0ed06",
  null
  
)

export default component.exports