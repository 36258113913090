<script>
import $service from "@/api/Service.js";

export default {
  props: {
    code: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      hasError: false,
    };
  },

  mounted() {
    this.getUrl();
  },

  methods: {
    async getUrl() {
      try {
        const { data } = await $service.get(
          `url/${this.$route.params.code || ""}`
        );
        setTimeout(() => {
          window.location.replace(data.response.url);
        }, 1000);
      } catch (error) {
        this.hasError = true;
      }
    },
  },
};
</script>

<template>
  <figure class="card">
    <div v-if="!hasError" class="card__exist">
      <figcaption class="card__title">
        <span>
          Redirect...
          <svg
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            class="lds-hourglass"
            style="background: none"
          >
            <defs>
              <clipPath id="lds-hourglass-cpid-61cd5c7dd1841">
                <rect x="0" y="14.2717" width="100" height="0">
                  <animate
                    attributeName="y"
                    calcMode="spline"
                    values="0;50;0;0;0"
                    keyTimes="0;0.4;0.5;0.9;1"
                    dur="3"
                    keySplines="0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7"
                    begin="0s"
                    repeatCount="indefinite"
                  ></animate>
                  <animate
                    attributeName="height"
                    calcMode="spline"
                    values="50;0;0;50;50"
                    keyTimes="0;0.4;0.5;0.9;1"
                    dur="3"
                    keySplines="0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7"
                    begin="0s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
                <rect x="0" y="50" width="100" height="50">
                  <animate
                    attributeName="y"
                    calcMode="spline"
                    values="100;50;50;50;50"
                    keyTimes="0;0.4;0.5;0.9;1"
                    dur="3"
                    keySplines="0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7"
                    begin="0s"
                    repeatCount="indefinite"
                  ></animate>
                  <animate
                    attributeName="height"
                    calcMode="spline"
                    values="0;50;50;0;0"
                    keyTimes="0;0.4;0.5;0.9;1"
                    dur="3"
                    keySplines="0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7;0.3 0 1 0.7"
                    begin="0s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </clipPath>
            </defs>
            <g transform="translate(50,50)">
              <g transform="scale(0.9)">
                <g transform="translate(-50,-50)">
                  <g transform="rotate(159.16 50 50)">
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      calcMode="linear"
                      values="0 50 50;0 50 50;180 50 50;180 50 50;360 50 50"
                      keyTimes="0;0.4;0.5;0.9;1"
                      dur="3s"
                      begin="0s"
                      repeatCount="indefinite"
                    ></animateTransform>
                    <path
                      d="M54.864,50L54.864,50c0-1.291,0.689-2.412,1.671-2.729c9.624-3.107,17.154-12.911,19.347-25.296 c0.681-3.844-1.698-7.475-4.791-7.475H28.908c-3.093,0-5.472,3.631-4.791,7.475c2.194,12.385,9.723,22.189,19.347,25.296 c0.982,0.317,1.671,1.438,1.671,2.729v0c0,1.291-0.689,2.412-1.671,2.729C33.84,55.836,26.311,65.64,24.117,78.025 c-0.681,3.844,1.698,7.475,4.791,7.475h42.184c3.093,0,5.472-3.631,4.791-7.475C73.689,65.64,66.16,55.836,56.536,52.729 C55.553,52.412,54.864,51.291,54.864,50z"
                      clip-path="url(#lds-hourglass-cpid-61cd5c7dd1841)"
                      fill="#ffb400"
                    ></path>
                    <path
                      d="M81,81.5h-2.724l0.091-0.578c0.178-1.122,0.17-2.243-0.022-3.333C76.013,64.42,68.103,54.033,57.703,50.483l-0.339-0.116 v-0.715l0.339-0.135c10.399-3.552,18.31-13.938,20.642-27.107c0.192-1.089,0.2-2.211,0.022-3.333L78.276,18.5H81 c2.481,0,4.5-2.019,4.5-4.5S83.481,9.5,81,9.5H19c-2.481,0-4.5,2.019-4.5,4.5s2.019,4.5,4.5,4.5h2.724l-0.092,0.578 c-0.178,1.122-0.17,2.243,0.023,3.333c2.333,13.168,10.242,23.555,20.642,27.107l0.338,0.116v0.715l-0.338,0.135 c-10.4,3.551-18.31,13.938-20.642,27.106c-0.193,1.09-0.201,2.211-0.023,3.333l0.092,0.578H19c-2.481,0-4.5,2.019-4.5,4.5 s2.019,4.5,4.5,4.5h62c2.481,0,4.5-2.019,4.5-4.5S83.481,81.5,81,81.5z M73.14,81.191L73.012,81.5H26.988l-0.128-0.309 c-0.244-0.588-0.491-1.538-0.28-2.729c2.014-11.375,8.944-20.542,17.654-23.354c2.035-0.658,3.402-2.711,3.402-5.108 c0-2.398-1.368-4.451-3.403-5.108c-8.71-2.812-15.639-11.979-17.653-23.353c-0.211-1.191,0.036-2.143,0.281-2.731l0.128-0.308 h46.024l0.128,0.308c0.244,0.589,0.492,1.541,0.281,2.731c-2.015,11.375-8.944,20.541-17.654,23.353 c-2.035,0.658-3.402,2.71-3.402,5.108c0,2.397,1.368,4.45,3.403,5.108c8.71,2.812,15.64,11.979,17.653,23.354 C73.632,79.651,73.384,80.604,73.14,81.191z"
                      fill="#222222"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
      </figcaption>
    </div>
    <div v-else class="card__not-exist">
      <figcaption class="card__title">URL does not exist</figcaption>
    </div>

    <svg
      class="card__icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 489.224 489.224"
      style="enable-background: new 0 0 489.224 489.224"
      xml:space="preserve"
    >
      <path
        d="M448.575,0H186.226c-21.673,0-39.298,17.622-39.298,39.297v106.248H40.65c-21.677,0-39.293,17.623-39.293,39.297v262.381
            c0,21.67,17.616,39.293,39.293,39.293h197.353c-0.239-1.199-0.548-2.351-0.694-3.582l-5.382-46.219H51.158V195.348h95.771v106.293
            c0,21.678,17.625,39.295,39.298,39.295h34.564l-5.804-49.796h-18.257v-95.792h95.796V225.4l49.801,24.417v-64.976
            c0-21.674-17.622-39.297-39.299-39.297H196.729V49.801h241.339V291.14H426.59l48.424,23.747c2.92,1.443,5.561,3.243,8.042,5.221
            c2.965-5.529,4.81-11.752,4.81-18.467V39.297C487.867,17.622,470.249,0,448.575,0z"
      />
      <path
        d="M460.361,344.666l-199.334-97.735c-1.607-0.778-3.326-1.168-5.042-1.168c-2.334,0-4.65,0.698-6.613,2.091
            c-3.423,2.413-5.235,6.518-4.754,10.684l25.664,220.556c0.538,4.587,3.78,8.41,8.221,9.677c1.034,0.308,2.107,0.454,3.16,0.454
            c3.421,0,6.744-1.558,8.932-4.311l67.064-83.959l101.404-35.194c4.374-1.509,7.391-5.498,7.686-10.117
            C467.022,351.038,464.51,346.709,460.361,344.666z"
      />
    </svg>
  </figure>
</template>

<style lang="scss" scoped>
.card {
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  box-shadow: 0 3px 15px hsla(0, 0%, 79.6%, 0.73);
  padding: 25px 30px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.card__title {
  font-weight: 500;
  font-size: 1.5rem;
  margin: 0;
  text-align: center;
}

.card__title a {
  color: #ffae01;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

.card__title a:hover {
  text-decoration: none;
}

.card__title span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.875rem;
  margin-top: 0.5em;
  text-align: center;
}
.card__title svg {
  width: 40px;
  height: 40px;
  margin-left: 0.5em;
}

.card__icon {
  width: 36px;
  height: 36px;
  fill: #525252;
  top: 15px;
  right: 15px;
  position: absolute;
}

@media (max-width: 600px) {
  .card__title {
    font-size: 1rem;
  }

  .card__title span {
    font-size: 1.125rem;
  }
}
</style>
