<template>
  <main class="wrapper">
    <redirect-card />
  </main>
</template>

<script>
// @ is an alias to /src
import redirectCard from "@/components/redirect-card.vue";

export default {
  name: "Home",
  components: {
    redirectCard,
  },
};
</script>

<style lang="scss">
.wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding: 0 15px;
}
</style>
