import axios from "axios";

const service = axios.create({
  baseURL:
    process.env.VUE_APP_API_URL ||
    `${new URL(window.location).origin}/srv/api/v1`,
  withCredentials: true,
});

export default service;
